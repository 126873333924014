module.exports = {
  host: "localhost",
  port: 9118,
  apiHost: {
    server       : "http://127.0.0.1/",
    client       : "/",
    proxyHeaders : ["SSL", "Ssl-Offload", "X-Forwarded-Proto", "Host", "X-Real-Ip", "X-Forwarded-For"],
  },
  cspUri: "https://csp-report.browser-intake-datadoghq.eu/api/v2/logs?dd-api-key=pub552607268219ad5b8d2647030dd2f46f&dd-evp-origin=content-security-policy&ddsource=csp-report&ddtags=service%3Aresursbank-pointshop%2Cenv%3Aproduction",
  googleAnalytics: {
    en_GB: {
      currencyCode: "SEK",
      accounts: ["UA-128549695-3"]
    },
  },
  isProduction: true
};
